import React, { useState } from "react";
import './SucessEntry.css'
import TickImg from '../../Assets/tick.png'
import { useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "../Components/NavigationBar";

const SucessEntry = () => {
    const location = useLocation();
    const { attendedGuest,availability, totalGuest } = location.state;

    console.log("location.state",location.state)

    const Navigate = useNavigate();
    const handleViewTicket = () => {
        Navigate('/scanner/scanqr');
    }

    return (
        <div>
            <NavigationBar />
            {/* Content */}
            <div style={{ height: "100vh" }}>
                <div className="SuccessMssg">
                    <div className='row'>
                        <div className='d-flex flex-column justify-content-center'>
                            <span className='heading-main fw-semibold'>Ticket Details</span>
                        </div>
                    </div>
                    <div className="SuccessMssgDiv shadow text-center p-2">
                        <div className="textdiv mt-5 px-3">
                        <b>{totalGuest === attendedGuest ? "All" : (totalGuest - availability + attendedGuest)} out of {totalGuest} guests entered!</b><br />
                           Scan QR on the ticket for others guests.
                        </div>
                        <div className="my-4">
                            <img src={TickImg} className="w-50 m-auto" alt="ticket_sucess_icon" />
                        </div>
                        <div>
                            <p className="fontSizeSeatBook">{totalGuest === attendedGuest ? "All" : (totalGuest - availability + attendedGuest)} out of {totalGuest} guests entered!</p>
                        </div>
                        <button className="viewticketbtn" onClick={handleViewTicket}>Done</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SucessEntry;