class ApiService {
    constructor() {
        this.data = JSON.parse(localStorage.getItem('userData')) || null;
    }

    setData(data) {
        this.data = data;
        localStorage.setItem('userData', JSON.stringify(data));
    }

    getData() {
        return this.data;
    }

    // static baseUrl = 'http://localhost:3001';
    static baseUrl = "https://my-front-seat-backend-production-3e06.up.railway.app";


    async get(endpoint) {
        try {
            const response = await fetch(`${ApiService.baseUrl}${endpoint}`);
            console.log("apiService:", response)
            if (!response.ok) {
                throw new Error(`Failed to fetch data from ${endpoint}`);
            }
            const data = await response.json();
            console.log("apiService: data", data)
            return data;
        } catch (error) {
            throw error;
        }
    }

    async post(endpoint, body) {
        try {
            const response = await fetch(`${ApiService.baseUrl}${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            if (!response.ok) {
                throw new Error(`Failed to post data to ${endpoint}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new ApiService();
