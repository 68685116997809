import React from "react";
import VendorSideBar from "../Components/VendorSideBar";
import NavigationBar from "../Components/NavigationBar";

const ManageCampaign = () => {
    return (
        <>
         <NavigationBar />
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10 col-12">
                        ManageCampaign
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageCampaign