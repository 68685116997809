import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { API_URL } from "../../config";

const LoginGK = () => {
    const [login_id, setLogin_id] = useState("");
    const [password, setPassword] = useState("");
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const navigate = useNavigate();
    const { event_id } = useParams();

    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const body = {
                login_id, password
            };
            const response = await axios.post(`${API_URL}/vendor/login/gatekeeper`, body);
            console.log("EVENT_ID: ", event_id, response.data.user.event === event_id, response.data.user.event, event_id)
            if (response.status === 200) {
                if (response.data.user.event === event_id) {
                    localStorage.setItem("userGK", JSON.stringify(response.data.user));
                    navigate('/scanner/scanqr');
                }else{
                    alert("You are not authorized to access this event");
                }
            }
        } catch (error) {
            alert("Unable to process!",error)
        } finally{
            setLoading(false);
        }
    }

    return (
        <>
            <div className="login-modal">
                <div className="w-50">
                    <div className="login-container shadow">
                        {/* <span onClick={onClose}>
              <BsXCircle className="closebtn bg-white rounded-pill" />
            </span> */}
                        <h1 className="book">
                            Scan <span className="your-fs"> Your Front Seat</span>
                        </h1>
                        {/* <p className="login">Login</p> */}
                        <>
                            <form>
                                <div className="div-num mb-3">
                                    <p className="enter-number">Login Id:</p>
                                    <input
                                        className="form-control mobileinput py-3 border-primary"
                                        type="text"
                                        name="login_id"
                                        placeholder="enter login id"
                                        value={login_id}
                                        onChange={(e) => setLogin_id(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="div-num mb-5">
                                    <p className="enter-number">Password:</p>
                                    <input
                                        className="form-control mobileinput py-3 border-primary"
                                        type="text"
                                        name="password"
                                        placeholder="enter secret password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <Button onClick={handleLogin} className="btn getotpbtn btn-primary w-50" disabled={loading}>
                                    {loading ? <SyncLoader animation="border" color="#FFFF" size="10" speedMultiplier={1} margin={4} /> : "Login"}
                                </Button>
                            </form>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginGK; 