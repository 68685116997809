import { useEffect, useRef } from 'react';
import Webcam from 'react-webcam'

const WebCamCapture=({onScan})=>{
const webCamRef= useRef(null);
const videoConstraints= {
    width: 300,
    height: 300,
    facingMode:"environment"
};
const capture=()=>{
    const imageSrc=webCamRef.current.getScreenshot();
    onScan(imageSrc)
}
useEffect(()=>{
    const timer = setInterval(()=>{
        capture();
    }, 500);
    return ()=>clearInterval(timer);
},[])
return(
    <div className="webcamscanner m-auto d-flex justify-content-center">
        <Webcam
        audio={false}
        ref={webCamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        onClick={()=>capture()}
        />
    </div>
)
}

export default WebCamCapture;