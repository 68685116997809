
import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './ScannedTicket.css';
import NavigationBar from "../Navbar";
import ReduceText from "../../utils.js";
import { API_URL } from "../../config.js";
import PuffLoader from "react-spinners/PuffLoader.js";
import SyncLoader from "react-spinners/SyncLoader.js";

const initialState = {
    ticket: {},
    event: {},
    bookedTicket: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_RESPONSE_DATA':
            return {
                ...state,
                ticket: action.payload.ticket,
                event: action.payload.event,
                bookedTicket: action.payload.bookedTicket,
                userName: action.payload.userName
            };
        default:
            return state;
    }
};

const ScannedTicket = () => {
    const location = useLocation();
    const { ticketId, bookedTicketId } = location.state || {};
    const [responseData, SetResponseData] = useState();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [guestCheckboxes, setGuestCheckboxes] = useState([]);
    const [guestCount, setGuestCount] = useState(0);
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const gateKeeper = JSON.parse(localStorage.getItem('userGK'));

    const getScannedTicket = async () => {
        try {
            const response = await axios.get(`${API_URL}/pay/ticket/${bookedTicketId}/${ticketId}`);
            if (response.status === 200) {
                const storeData = response.data;
                dispatch({
                    type: 'SET_RESPONSE_DATA',
                    payload: {
                        ticket: storeData.ticket,
                        event: storeData.event,
                        bookedTicket: storeData.booking,
                        userName: storeData.userName
                    },
                });

                // Initialize guest checkboxes
                const totalGuests = storeData.booking.bookedTicketQuantities;
                setGuestCheckboxes(Array(totalGuests).fill(false));
                setGuestCount(0); // Reset guest count on new data
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Error fetching ticket data:", error);
            alert("Unable to fetch ticket data.");
        } finally {
            setIsLoading(false); // Once data is fetched, set loading to false
        }
    };

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);
        return `${day}-${month}-${year}`;
    };

    const formatTime = (timeString) => {
        if (timeString == null || timeString === undefined || timeString === '') {
            timeString = "00:00:00";
        }

        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return formattedTime;
    };

    const handleValidateEntry = async () => {
        setLoading(true);
        try {
            const body = {
                total_no_of_Guest: state.bookedTicket.bookedTicketQuantities,
                no_of_Guest_attended: guestCount,
                attended_event_ticket_id: ticketId,
                order_id: bookedTicketId,
            };
            const updateValidateEntries = await axios.put(`${API_URL}/vendor/update-entries/${gateKeeper._id}`, body);
            if (updateValidateEntries.status === 200) {
                const totalGuest = state.bookedTicket.bookedTicketQuantities;
                const attendedGuest = guestCount

                Navigate('/scanner/entred', { state: { attendedGuest, availability : state.bookedTicket.available_entry, totalGuest } });
            }
        } catch (error) {
            alert("Unable to process!");
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (index) => {
        setGuestCheckboxes((prevCheckboxes) => {
            const newCheckboxes = [...prevCheckboxes];
            newCheckboxes[index] = !newCheckboxes[index]; // Toggle the checkbox
            return newCheckboxes;
        });
    };

    useEffect(() => {
        // Update guest count based on checked checkboxes
        const count = guestCheckboxes.filter((checked) => checked).length;
        setGuestCount(count);
    }, [guestCheckboxes]);

    useEffect(() => {
        if (bookedTicketId && ticketId) {
            getScannedTicket();
        }
    }, [bookedTicketId, ticketId]);

    return (
        <div>
            <NavigationBar />
            {
                isLoading ? (
                    <>
                        <div className='d-flex justify-content-center align-items-center' style={{ height: "50vh" }}>
                            <PuffLoader size={48} loading={isLoading} />
                        </div>
                    </>
                ) :
                    (
                        <div className="m-3">
                            {
                                (new Date(state.event.event_ends_date) < new Date()) ? (
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-center align-items-center text-center">
                                            <span>Invalid QR! Scan a Valid Ticket</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="text-center">
                                            <span className="heading-main fw-semibold">Check Ticket Details</span>
                                        </div>
                                        {
                                            (state.ticket && state.event && state.bookedTicket) ? <>
                                                <div className="card eventDetailStyle">
                                                    <div className="card-body">
                                                        <span className="semi18pop fw-semibold">{state.event.event_name}</span>
                                                        <div className="row mt-3">
                                                            <div className="col-5 d-flex flex-column">
                                                                <span className="fs-pop-14">Venue</span>
                                                                <span className="fs-pop-12">{state.event.event_venue} {state.event.event_city}</span>
                                                            </div>
                                                            <div className="col-3 d-flex flex-column">
                                                                <span className="fs-pop-14">Time</span>
                                                                {/* <span className="fs-pop-12">Hil, {formatTimespanDate(state.event.event_starts_Time)}</span> */}
                                                                <span className="fs-pop-12">{formatTime(state.event.event_starts_Time)}</span>
                                                            </div>
                                                            <div className="col-4 d-flex flex-column">
                                                                <span className="fs-pop-14">Date</span>
                                                                <span className="fs-pop-12">{formatDate(state.bookedTicket.eventreserveDate)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card eventDetailStyle mt-4">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="fs-pop-16 fw-semibold">{ReduceText(state.ticket.ticket_Name || "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="fs-pop-16 fw-semibold">Entry</p>
                                                            </div>
                                                        </div>

                                                        {
                                                            state.bookedTicket.available_entry === 0 ? (
                                                                <div className="row mt-3">
                                                                    <p>QR already validated! No more guest entries.</p>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div>
                                                                        {Array.from({ length: state.bookedTicket.bookedTicketQuantities }, (_, i) => (
                                                                            <div className="row" key={i}>
                                                                                <div className="col-6">
                                                                                    <span className="fs-pop-16">
                                                                                        {i === 0 ? state.userName : `Guest ${i + 1}`}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <input
                                                                                        className="checkboxSize"
                                                                                        type="checkbox"
                                                                                        checked={guestCheckboxes[i] || i < (state.bookedTicket.bookedTicketQuantities - state.bookedTicket.available_entry)}
                                                                                        disabled={i < (state.bookedTicket.bookedTicketQuantities - state.bookedTicket.available_entry)}
                                                                                        onChange={() => handleCheckboxChange(i)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </>
                                                            )
                                                        }
                                                    </div>

                                                    {
                                                        state.bookedTicket.available_entry === 0 ? (
                                                            ''
                                                        ) : (
                                                            <div className="card-footer" style={{ background: "white" }}>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <p className="fs-pop-16 fw-semibold">Total Amount</p>
                                                                        <p className="fs-pop-16 fw-semibold">Cover Amount</p>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="fs-pop-15">₹ {(state.bookedTicket.amount / 100).toFixed(2) || 0.00}</p>
                                                                        <p className="fs-pop-15">₹ 0.00</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="text-center" style={{ marginTop: "50px" }}>
                                                    {
                                                        state.bookedTicket.available_entry === 0 ? (
                                                            ""
                                                            // <button disabled className="btn-style">Validate Entry</button>
                                                        ) : (

                                                            <button onClick={handleValidateEntry} className="btn-style" disabled={loading}>
                                                                {loading ? <SyncLoader animation="border" color="#FFFF" size="10" speedMultiplier={1} margin={4} /> : "Validate Entry"}
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            </> : <><p>No Data!</p></>
                                        }
                                    </>
                                )
                            }
                        </div>
                    )
            }
        </div>
    );
};

export default ScannedTicket;
