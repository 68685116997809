import './App.css';
import { Route, Routes } from 'react-router-dom';
import AddNewEvent from './Vendor/Pages/AddNewEvent';
import LoginPage from './Vendor/Pages/LoginPage';
import AccountSetUp from './Vendor/Pages/AccountSetUp';
import DashBoard from './Vendor/Pages/DashBoard';
import ManageCampaign from './Vendor/Pages/ManageCampaign';
import TicketScan from './Vendor/Pages/TicketScan';
import YourEvents from './Vendor/Pages/YourEvents';
import CreateEvent from './Vendor/Pages/CreateEvent';
import QRScanner from './Ticket-Scanner/QRScanner/QRScanner';
import ScannedTicket from './Ticket-Scanner/QRScanner/ScannedTicket';
import LoginGK from './Ticket-Scanner/Auth/LoginGK';
import SucessEntry from './Vendor/Pages/SucessEntry';
import VendorEvents from './Vendor/Pages/VendorEents';
import EditAddNewEvent from './Vendor/Components/edit_eventbyID/EditAddNewEvent';
import Payouts from './Vendor/Components/PayOuts';

function App() {
  // const userGK = localStorage.getItem('userGK');

  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<AccountSetUp />} />

        {/* Vendor Routes */}
       {localStorage.getItem('userData')?(
        <>
         <Route path="/details" element={<AddNewEvent />} />
        <Route path="/vendor/dashboard" element={<DashBoard />} />
        <Route path="/vendor/yourevents" element={<VendorEvents/>} />
        <Route path="/vendor/ticketscan" element={<TicketScan />} />
        <Route path="/vendor/create-event" element={<CreateEvent />} />
        <Route path="/vendor/campaigns" element={<ManageCampaign />} />
        <Route path="/vendor/payouts" element={<Payouts />} />
        <Route path="/vendor/event-detail/:event_id" element={<YourEvents />} />
        <Route path="/vendor/editevent-detail/:event_id" element={<EditAddNewEvent />} />
        </>
       ):
       <Route path="*" element={<NotAuthorized />} />
       }

        {/* Event Gate Keeper */}
        <Route path="/scanner/scanqr/:unique_id/:event_id" element={<LoginGK />} />

        {/* Conditionally rendered routes for GK */}
        {localStorage.getItem('userGK') ? (
          <>
            <Route path="/scanner/scanqr" element={<QRScanner />} />
            <Route path="/scanner/scanticket" element={<ScannedTicket />} />
            <Route path="/scanner/entred" element={<SucessEntry />} />
          </>
        ) : (
          <Route path="*" element={<NotAuthorized />} />
        )}
      </Routes>
    </div>
  );
}

// Component to handle "Not Authorized" case
function NotAuthorized() {
  return (
    <div>
      <h2>Sorry! You are not authorized.</h2>
    </div>
  );
}

export default App;
