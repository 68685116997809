import React, { useEffect, useState } from "react";
import VendorSideBar from "../Components/VendorSideBar";
import axios from "axios";
import './TicketScannerPage.css';
import { API_URL } from "../../config";
import NavigationBar from "../Components/NavigationBar";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { IoIosCopy } from "react-icons/io";

const TicketScan = () => {
    const [events, setEvents] = useState([]);
    const user = JSON.parse(localStorage.getItem('userData'));
    const [eventName, setEventName] = useState("");
    const [name, setName] = useState("");
    const [generatedLink, SetGeneratedLink] = useState();
    const [isdisabled, SetIsdisabled] = useState(true);
    const [showTotalEntries, SetShowTotalEntries] = useState(false);
    const [totalGuestVisited, SetTotalGuestVisited] = useState(0);

    const getEventList = async () => {
        try {
            const result = await axios.get(`${API_URL}/vendor/events/${user._id}`);
            if(result.data && result.data.length>0){
                setEvents(result.data);
            }
            console.log("Events : ", events)
        } catch (error) {
            alert("Something went wrong");
        }
    };

    // Function to generate alphanumeric key
    const generateAlphanumericKey = (length) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let key = '';
        for (let i = 0; i < length; i++) {
            key += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return key;
    };

    const handleGeneratelink = async (e) => {
        e.preventDefault();
        const body = {
            loginId: user.name.slice(0, 3),
            event_id: eventName
        };
        const response = await axios.post(`${API_URL}/vendor/generate-link`, body);
        if (response.status === 200) {
            alert("Link generated successfully");
            requestLink(eventName);
            setName(response.data.eventName)
        } else {
            alert(response.data.message);
        }
    };

    const requestLink = async (event_id) => {
        const response = await axios.get(`${API_URL}/vendor/get-link/${event_id}`);
        if (response.status === 200) {
            const link = response.data.Links;
            SetIsdisabled(true);
            SetShowTotalEntries(false);
            if (link && response.data.eventName) {
                SetGeneratedLink(link);
                setName(response.data.eventName)
            }

            const candidatesresponse = await axios.get(`${API_URL}/vendor/get-attended-candidates/${event_id}`);
            if (candidatesresponse.status === 200) {
                const attendedCandidates = candidatesresponse.data.attendedCandidates;
                if (attendedCandidates) {
                    SetTotalGuestVisited(attendedCandidates);
                }
            }
        } else {
            SetGeneratedLink('');
            SetIsdisabled(false);
            SetShowTotalEntries(false);
        }
    };

    // Function to handle the copy of card content
    const handleCopy = () => {
        const ticketScannerLink = `https://www.hostyourfrontseat.in/scanner/scanqr/event-${name.replace(/\s+/g, '_')}/${generatedLink?.event_id || ""}`;
        const loginId = generatedLink?.login_id;
        const password = generatedLink?.password;

        const cardContent = `
            Ticket Scanner Link: ${ticketScannerLink}
            Login id: ${loginId}
            Password: ${password}
        `;

        navigator.clipboard.writeText(cardContent)
            .then(() => {
                alert("Link copied!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    useEffect(() => {
        getEventList();
    }, []);

    return (
        <>
            <NavigationBar />
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10 col-12">
                        <div className='lists'>
                            <div className='d-flex justify-content-between border-bottom border-primary'>
                                <span className='vender-heading fw-semibold'>Ticket Scanner</span>
                            </div>
                            <div className='ListofEvents mt-3'>
                                <label className='listheading fw-semibold'>Select Event</label>
                                <select
                                    className='form-control border-primary'
                                    name='eventName'
                                    value={eventName}
                                    onChange={(e)=>{setEventName(e.target.value); requestLink(e.target.value);}}
                                >
                                    <option value="" disabled>Select Event</option>
                                    {events.length > 0 &&
                                        events.map((event) =>
                                            !event.isExpired ? (
                                                <option key={event._id} value={event._id}>
                                                    {event.event_name}
                                                </option>
                                            ) : null
                                        )}
                                </select>

                                <div className="mt-5 d-flex justify-content-between">
                                    <button className={`generate-btn ${isdisabled ? 'generate-btn-disable' : 'generate-btn-enable'}`} disabled={isdisabled} onClick={handleGeneratelink}>Generate Link</button>
                                    {generatedLink && (
                                        <>
                                            {!showTotalEntries ? (
                                                <button className="generate-btn generate-btn-enable" onClick={() => SetShowTotalEntries(true)}>View Details</button>
                                            ) : (
                                                <button className="generate-btn generate-btn-enable" onClick={() => SetShowTotalEntries(false)}>Hide Details</button>
                                            )}
                                        </>
                                    )}
                                </div>

                                {generatedLink ? (
                                    <div>
                                        {!generatedLink.isExpired && (
                                            <div className="mt-5 d-flex align-items-end">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <p><b>Ticket Scanner Link:</b> https://www.hostyourfrontseat.in/scanner/scanqr/event-{name}/{generatedLink?.event_id || ""}</p>
                                                        <p><b>Login id:</b> {generatedLink?.login_id}</p>
                                                        <p><b>Password:</b> {generatedLink?.password}</p>
                                                    </div>
                                                </div>
                                                <IoIosCopy  className="text-primary ms-2" size={30} onClick={handleCopy} />
                                            </div>
                                        )}
                                    </div>
                                ) : ""}

                                {showTotalEntries && (
                                    <div className="my-3">
                                        <div className="card w-50">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className='dashboard-summary fw-semibold'>Guests Entered</p>
                                                        <p className="count-style fw-semibold">{totalGuestVisited}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 100% </span>Up from last year</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TicketScan;
