import { useState } from "react";
import WebCamCapture from "./WebCamCapture";
import jsQR from "jsqr";
import NavigationBar from "../Navbar";
import { useNavigate } from "react-router-dom";

const QRScanner =()=>{
    const navigate = useNavigate();

    const handleScan= (imageSrc)=>{
        if(imageSrc){
            const image = new Image();

            image.src = imageSrc;
            image.onload=()=>{
                const canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                const imageData = ctx.getImageData(0,0,canvas.width, canvas.height);

                const code = jsQR(imageData.data, imageData.width, imageData.height, {inversionAttempts:"dontInvert"});

                if(code){
                    const qrCodeValue = code.data; // Example scanned value
                    console.log("qrCodeValue ___ ",qrCodeValue);
                    // const [ticketId, bookedTicketId] = qrCodeValue.split('_');
                    const ticketId = qrCodeValue.split('_')[0]
                    const bookedTicketId = (qrCodeValue.split('_')[1] === "order")? ("order_" + qrCodeValue.split('_order_')[1]) : (qrCodeValue.split('_')[1])
                    console.log("ticketId : ",ticketId,"bookedTicketId :", bookedTicketId);
                    navigate('/scanner/scanticket', { state: { ticketId, bookedTicketId } })
                }
                else{
                    console.log("Error!")
                }
            }
        }
    }
    return(
        <div>
            <NavigationBar />
            <div className="text-center">
                <p className="heading-main fw-semibold my-3 mb-5">Scan the QR Code</p>
                <WebCamCapture onScan={handleScan} />
            </div>
        </div>
    )
}

export default QRScanner;