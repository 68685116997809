import React, { useEffect, useState } from "react";
import VendorSideBar from "../Components/VendorSideBar";
import axios from "axios";
import { API_URL } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import NavigationBar from "../Components/NavigationBar";

const YourEvents = () => {
    const { event_id } = useParams();
    const [eventDetail, setEventDetail] = useState([])
    const [event_ID, setEvent_ID] = useState('')
    const [users, SetUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [ticket_hosted, setTicket_hosted] = useState(0);
    const [ticket_sold, setTicket_sold] = useState(0);
    const [Revenue, setRevenue] = useState(0);
    const navigate = useNavigate();

    
    const getEventDetails = async () => {
        try {
            const id = event_id;

            // Fetch Event Details
            const { data: eventData, status } = await axios.get(`${API_URL}/api/eventdetail/${id}`);
            if (status === 200 && eventData.length > 0) {
                setEventDetail(eventData[0]); // Update state with event details

    
                let total_ticket = 0;
                let ticket_sell = 0;

                // Calculate ticket details
                for (const ticket of eventData[0].ticket) {
                    total_ticket += ticket.total_Quantity;
                    ticket_sell += ticket.total_Quantity - ticket.ticket_avability;
                }

                // Update ticket states
                setTicket_hosted(total_ticket);
                setTicket_sold(ticket_sell);
            }

            // Fetch User Details
            const userResponse = await axios.get(`${API_URL}/pay/events/${event_ID}`);
            if (userResponse.status === 200 && userResponse.data.users) {
                SetUsers(userResponse.data.users);
            } else {
                console.warn("No user data found!");
            }

            // Fetch and Calculate Revenue
            const soldData = await axios.get(`${API_URL}/vendor/eventbooking/${event_ID}`);
            if (soldData.status === 200) {
                // Check if soldData.data is an array
                if (Array.isArray(soldData.data)) {
                    const total_revenue = soldData.data.reduce((acc, booking) => {
                        return acc + ((booking.amount)/100 || 0); // Ensure to handle cases where paid_Amount might be undefined
                    }, 0);
                    setRevenue(total_revenue);
                } else {
                    console.warn("Expected soldData.data to be an array, but received:", soldData.data);
                }
            }


        } catch (error) {
            console.error("Failed to fetch event details:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Optional: Log users when updated
    useEffect(() => {
        console.log("Updated users:", users);
    }, [users]);


    const handleEdit = (event_id) => {
        navigate(`/vendor/editevent-detail/${event_id}`)
    }

    useEffect(() => {
        getEventDetails();
        if(eventDetail){
            setEvent_ID(eventDetail._id)
        }
    }, [event_id]);

    return (
        <>
            <NavigationBar />
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10 col-12">
                        <div>
                            {isLoading ? (
                                <div className='d-flex justify-content-center align-items-center my-5' style={{ height: "100vh" }} >
                                    <PuffLoader size={48}
                                        loading={isLoading} />
                                </div>
                            ) : (
                                <>
                                    <div className='d-flex justify-content-between border-bottom border-primary'>
                                        <span className="vender-heading">{eventDetail.event_name}</span>
                                        <span><button className="edit-btn" onClick={e => handleEdit(event_id)}>Edit</button></span>
                                    </div>
                                    <div className="row mb-5 mt-3">
                                        <div className='col-lg-3 col-md-3'>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className='dashboard-summary fw-semibold'>Total Tickets Hosted</p>
                                                            <p className="count-style fw-semibold">{ticket_hosted}</p>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                    <div>
                                                        <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.8% </span>
                                                            <span>Up from year</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-3'>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className='dashboard-summary fw-semibold'>Total Tickets Sold</p>
                                                            <p className="count-style fw-semibold">{ticket_sold ? ticket_sold : "0"}</p>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                    <div>
                                                        <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 1.3% </span>
                                                            <span>Up from past week</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className='dashboard-summary fw-semibold'>Total Revenue</p>
                                                            <p className="count-style fw-semibold">{Revenue ? Revenue : "0"}</p>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                    <div>
                                                        <span className='dashboard-summary fw-semibold'><span className="text-success"><HiMiniArrowTrendingUp /> 4.3% </span>
                                                            <span>Down from yesterday</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            }
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <p className="vender-heading">Ticket Selling Details </p>
                                <table className="table table-hover">
                                    <thead className="tableHead">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th className="hideit_768" scope="col">Customer Name</th>
                                            <th className="hideit_768" scope="col">Date</th>
                                            <th className="hideit_768" scope="col">No. of Tickets</th>
                                            <th className="hideit_768" scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.length > 0 ? (
                                            users.map((event, index) => {
                                                return (
                                                    <tr className="tableDataRow" key={event.booking._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{event.user.firstName + " " + event.user.lastName}</td>
                                                        <td className="hideit_768">{new Date(event.booking.eventreserveDate).toLocaleDateString('en-GB').replace(/\//g, '-')}</td>
                                                        <td className="hideit_768">{event.booking.bookedTicketQuantities}</td>
                                                        <td className="hideit_768">{(event.booking.amount / 100).toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })
                                        ) : <p>.........No Event Added........</p>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default YourEvents