import React, { useEffect, useState } from 'react';
import '../../Pages/AddNewEvent.css';
import { BsRecordCircleFill, BsCheckCircleFill, BsCircle, BsCircleFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import EditTicketDetail from './EditTicketDetail';
import EditAddEventDetails from './EditAddEventDetails';
import EditAddOn from './EditAddOn';
import { API_URL } from '../../../config';
import NavigationBar from '../NavigationBar';
import axios from 'axios';

function EditAddNewEvent() {
    const {event_id}=useParams();
    const [isActive, setIsActive] = useState({
        tab: "add-details",
        form: "basics",
    });
    const [eventDetail, setEventDetail] = useState({});
    const [eventFormDetail, setEventFormDetail] = useState({});
    const [validTabs, setValidTabs] = useState({
        "add-details": false,
        "ticket-detail": false,
        "add-on": false
    });

    const handleNext = (Nexttab, event, isValid) => {
        console.log(Nexttab, event, isValid);
        setValidTabs({ ...validTabs, [isActive.tab]: isValid });
        setIsActive({ ...isActive, tab: Nexttab });
        if (event) {
            setEventDetail(event);
        }
    }

    const handleAuthAddOn = (Nexttab, isValid) => {
        setValidTabs({ ...validTabs, [isActive.tab]: isValid });
        setIsActive({ ...isActive, tab: Nexttab });
    }

    const ActiveTab = (tab) => {
        const tabsOrder = ["add-details", "ticket-detail", "add-on"];
        const currentIndex = tabsOrder.indexOf(isActive.tab);
        const nextIndex = tabsOrder.indexOf(tab);
        
        if (nextIndex <= currentIndex + 1 && validTabs[tabsOrder[currentIndex]]) {
            setIsActive({ ...isActive, tab: tab });
        } else {
            alert("Please Fill Form");
        }
    }

    const renderIcon = (tab) => {
        const tabsOrder = ["add-details", "ticket-detail", "add-on"];
        const currentIndex = tabsOrder.indexOf(isActive.tab);
        const tabIndex = tabsOrder.indexOf(tab);
        
        if (tabIndex > currentIndex) {
            return <BsCircleFill className='ms-2 text-light' />;
        } else if (tab === isActive.tab) {
            return <BsRecordCircleFill className='ms-2 text-primary bg-white rounded-5'/>;
        } else if (validTabs[tab]) {
            const element = document.getElementById(tab);
            if (element) {
                element.style.cssText = "color: black; background-color: #bfdaf1 !important;";
            } 
            return <BsCheckCircleFill className='ms-2 text-primary' />;
        }
    }
    
    const  getEventFromDetails = async ()=>{
        try {
            const eventFormData = await axios.get(`${API_URL}/api/eventdetail/${event_id}`)
            if(eventFormData.status===200){
                const data = eventFormData.data[0]
                console.log(data);
                if(data){
                    setEventFormDetail(data);
                    console.log("Events Details are : ", eventFormDetail)
                }
            }else{
                alert("Error");
            }
        } catch (error) {
            alert("Server Error! please try again later.")
        }
    }
        useEffect(()=>{
        getEventFromDetails();
    },[])
    return (
        <div>
            <NavigationBar />
            <div className='container-main'>
                {/* <p className='mainheading'>Add your Event</p> */}
                <div className='d-flex justify-content-between mt-3'>
                    <button 
                        className={`fw-semibold ${isActive.tab === "add-details" ? "isActive" : "tabheading"}`}
                        id="add-details" 
                        onClick={() => ActiveTab("add-details")}
                    >   {renderIcon("add-details")}&nbsp;
                        Add Event Details
                    </button>
                    <button 
                        className={`fw-semibold  ${isActive.tab === "ticket-detail" ? "isActive" : "tabheading"}`}
                        id="ticket-detail" 
                        onClick={() => ActiveTab("ticket-detail")}
                        disabled={!validTabs["add-details"]}
                    >   {renderIcon("ticket-detail")}&nbsp;
                        Tickets
                    </button>
                    <button 
                        className={`fw-semibold  ${isActive.tab === "add-on" ? "isActive" : "tabheading"}`}
                        id="add-on" 
                        onClick={() => ActiveTab("add-on")}
                        disabled={!validTabs["ticket-detail"]}
                    >   {renderIcon("add-on")}&nbsp;
                        Add-Ons
                    </button>
                </div>
            </div>
            {
                (isActive.tab === "ticket-detail") ? 
                    <EditTicketDetail onNext={handleAuthAddOn} eventDetail={eventDetail} EditTicketDetails={eventFormDetail.ticket}/> :
                (isActive.tab === "add-on") ? 
                    <EditAddOn onNext={(nextTab) => setIsActive({ ...isActive, tab: nextTab })} eventDetail={eventDetail} EditAddonDetails={eventFormDetail.Addon[0]} /> : 
                    <EditAddEventDetails onNext={handleNext} EditEventDetails={eventFormDetail}/>
            }
        </div>
    )
}

export default EditAddNewEvent;
